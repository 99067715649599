$main-back-original: #f5f5f1;
$main-back: #ffffff;

@mixin navbar($position, $color) {
  position: $position;
  top: 0;
  width: 80%;
  padding: 40px 10% 15px;
  background-color: $color;
  z-index: 2;
  height: 30px;
  @media (max-width: 470px) {
    width: 100vw;
    margin: 0;
    padding: 20px 0;
    height: 50px;
  }

  .navbar-container {
    display: flex;
    justify-content: space-between;

    @media (max-width: 470px) {
      padding: 20px 6%;
    }

    @media (min-width: 1600px) {
      max-width: 1240px;
      margin: auto;
    }
    a {
      transition: all 0.3s;
      color: black;
      text-decoration: none;
      font-size: 16px;

      @media (max-width: 470px) {
        font-size: 16px;
      }
    }

    a:hover {
      color: #6600f7;
    }

    .navbar-categoroies {
      width: 180px;
      display: flex;
      justify-content: space-between;
      @media (max-width: 470px) {
        display: none;
      }
    }
  }
}

//Drop-down navbar scroll effect

@keyframes slideDown {
  from {
    top: -100px;
  }
  to {
    top: 0;
  }
}

@keyframes slideUp {
  from {
    top: 0px;
  }
  to {
    top: -100px;
  }
}

.navbar {
  @include navbar(absolute, rgba(255, 0, 0, 0) (255, 255, 255));
  transition: all 0.5s;
}

.navbar-up {
  @include navbar(fixed, $main-back);
  top: -100px;
  transition: all 0.5s;
  animation: slideUp 0.5s;
}

.navbar-fixed {
  @include navbar(fixed, $main-back);
  animation: slideDown 0.5s;
}

//Drop-down projects list section

@keyframes createBox {
  from {
    height: 200px;
    width: 0px;
  }
  to {
    height: 200px;
    width: 200px;
  }
}

.drop-down {
  position: absolute;
  background-color: white;
  margin-top: 20px;
  width: 200px;
  height: 280px;
  border-top: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  display: none;

  .drop-down-link {
    margin: 10px 0px;
    margin-left: 30px;
    text-decoration: none;
    padding: 0;
    color: black;
  }
}

.navbar-button-projects:hover {
  height: 40px;
  .drop-down {
    display: flex;
    /* animation: createBox 0.3s; */
  }
}

.mobile-menu {
  width: 50px;
  height: 30px;

  @media (min-width: 470px) {
    display: none;
  }
  button {
    background: none;
    border: none;
  }
}

.burger-menu-icon {
  width: 26px;
  height: 16px;
  @media (min-width: 470px) {
    display: none;
  }
}

@keyframes openBurgerMenu {
  from {
    left: 100vw;
  }
  to {
    left: 0;
  }
}

@keyframes closeBurgerMenu {
  from {
    left: 0;
  }
  to {
    left: 100vw;
  }
}

.burger-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #6600f7;
  padding-top: 40px;

  @media (min-width: 470px) {
    display: none;
  }
}

.burger-menu-top {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  button {
    background: none;
    border: none;
    padding: 0;
    img {
      width: 20px;
    }
  }
}

@keyframes closeBurgerProjectsDropdown {
  from {
    height: 200px;
    opacity: 1;
  }
  to {
    opacity: 0;
    height: 0px;
  }
}

@keyframes openBurgerProjectsDropdown {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    opacity: 1;
    height: 200px;
  }
}

.burger-menu-projects {
  width: 90%;
  margin: auto;
  div {
    height: 200px;
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
    border-bottom: 1px solid white;
    a {
      margin-top: 15px;
      color: white !important;
    }
  }
}

.burger-menu-projects-button {
  margin-top: 50px;
  width: 100%;
  background: none;
  border: none;
  padding: 0;
  color: white !important;
  border-bottom: 1px solid white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}

.burger-menu-about-button {
  width: 90%;
  margin: auto;
  font-size: 16px;
  margin-top: 20px;
  a {
    color: white !important;
  }
}
