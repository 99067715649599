@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.home {
  background-color: none;
  margin: 0 10%;
  max-width: 1240px;
}

@media (min-width: 1600px) {
  .home {
    margin: 0 auto;
    max-width: 1240px;
  }
}

@media (max-width: 470px) {
  .home {
    font-size: 14px;
    margin: 0 6%;
  }
}

.top-container {
  margin-top: 180px;
  border: 1px solid none;
  width: 65%;
}

@media (max-width: 470px) {
  .top-container {
    width: 100%;
    margin-top: 120px;
  }
}

.top-container h4 {
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 470px) {
  .top-container h4 {
    font-size: 14px;
  }
}

.top-container h1 {
  font-size: 48px;
  font-weight: 500;
}

@media (max-width: 470px) {
  .top-container h1 {
    font-size: 37px;
  }
}

.top-container a {
  padding: 15px 20px;
  width: 120px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  color: black;
  border-radius: 50vh;
  font-weight: 100;
  border: 1px solid black;
  background: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.top-container a:hover {
  color: #6600f7;
  border: 1px solid #6600f7;
}

.projects-container {
  margin-top: 100px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media (max-width: 470px) {
  .projects-container {
    display: block;
  }
}

.projects-container .column-2 {
  margin-top: 80px;
}

.project-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 460px;
  margin-bottom: 100px;
}

@media (max-width: 470px) {
  .project-card {
    width: 100%;
  }
}

.project-card div {
  height: 460px;
  width: 460px;
}

@media (max-width: 470px) {
  .project-card div {
    width: 100%;
    height: 100vw;
  }
}

.project-card div img {
  width: 100%;
  height: auto;
}

@media (max-width: 470px) {
  .project-card div img {
    width: 100%;
  }
}

.project-card span {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.6);
}

@media (max-width: 470px) {
  .project-card span {
    font-size: 16px;
    margin-top: 5px;
  }
}

.project-card h2 {
  font-size: 36px;
  font-weight: 600;
  margin: 20px 0px;
}

.project-card p {
  font-size: 14px;
  font-weight: 400;
  margin: 0px 0px;
}

.project-card a {
  text-decoration: none;
  color: white;
}

.project-card-hover {
  background-color: #6600f7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 460px;
  width: 460px;
}

.card-icon {
  height: 70px !important;
  width: 70px !important;
}

@-webkit-keyframes slideDown {
  from {
    top: -100px;
  }
  to {
    top: 0;
  }
}

@keyframes slideDown {
  from {
    top: -100px;
  }
  to {
    top: 0;
  }
}

@-webkit-keyframes slideUp {
  from {
    top: 0px;
  }
  to {
    top: -100px;
  }
}

@keyframes slideUp {
  from {
    top: 0px;
  }
  to {
    top: -100px;
  }
}

.navbar {
  position: absolute;
  top: 0;
  width: 80%;
  padding: 40px 10% 15px;
  background-color: rgba(255, 0, 0, 0) 255, 255, 255;
  z-index: 2;
  height: 30px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

@media (max-width: 470px) {
  .navbar {
    width: 100vw;
    margin: 0;
    padding: 20px 0;
    height: 50px;
  }
}

.navbar .navbar-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media (max-width: 470px) {
  .navbar .navbar-container {
    padding: 20px 6%;
  }
}

@media (min-width: 1600px) {
  .navbar .navbar-container {
    max-width: 1240px;
    margin: auto;
  }
}

.navbar .navbar-container a {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: black;
  text-decoration: none;
  font-size: 16px;
}

@media (max-width: 470px) {
  .navbar .navbar-container a {
    font-size: 16px;
  }
}

.navbar .navbar-container a:hover {
  color: #6600f7;
}

.navbar .navbar-container .navbar-categoroies {
  width: 180px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media (max-width: 470px) {
  .navbar .navbar-container .navbar-categoroies {
    display: none;
  }
}

.navbar-up {
  position: fixed;
  top: 0;
  width: 80%;
  padding: 40px 10% 15px;
  background-color: #ffffff;
  z-index: 2;
  height: 30px;
  top: -100px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-animation: slideUp 0.5s;
          animation: slideUp 0.5s;
}

@media (max-width: 470px) {
  .navbar-up {
    width: 100vw;
    margin: 0;
    padding: 20px 0;
    height: 50px;
  }
}

.navbar-up .navbar-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media (max-width: 470px) {
  .navbar-up .navbar-container {
    padding: 20px 6%;
  }
}

@media (min-width: 1600px) {
  .navbar-up .navbar-container {
    max-width: 1240px;
    margin: auto;
  }
}

.navbar-up .navbar-container a {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: black;
  text-decoration: none;
  font-size: 16px;
}

@media (max-width: 470px) {
  .navbar-up .navbar-container a {
    font-size: 16px;
  }
}

.navbar-up .navbar-container a:hover {
  color: #6600f7;
}

.navbar-up .navbar-container .navbar-categoroies {
  width: 180px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media (max-width: 470px) {
  .navbar-up .navbar-container .navbar-categoroies {
    display: none;
  }
}

.navbar-fixed {
  position: fixed;
  top: 0;
  width: 80%;
  padding: 40px 10% 15px;
  background-color: #ffffff;
  z-index: 2;
  height: 30px;
  -webkit-animation: slideDown 0.5s;
          animation: slideDown 0.5s;
}

@media (max-width: 470px) {
  .navbar-fixed {
    width: 100vw;
    margin: 0;
    padding: 20px 0;
    height: 50px;
  }
}

.navbar-fixed .navbar-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media (max-width: 470px) {
  .navbar-fixed .navbar-container {
    padding: 20px 6%;
  }
}

@media (min-width: 1600px) {
  .navbar-fixed .navbar-container {
    max-width: 1240px;
    margin: auto;
  }
}

.navbar-fixed .navbar-container a {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: black;
  text-decoration: none;
  font-size: 16px;
}

@media (max-width: 470px) {
  .navbar-fixed .navbar-container a {
    font-size: 16px;
  }
}

.navbar-fixed .navbar-container a:hover {
  color: #6600f7;
}

.navbar-fixed .navbar-container .navbar-categoroies {
  width: 180px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media (max-width: 470px) {
  .navbar-fixed .navbar-container .navbar-categoroies {
    display: none;
  }
}

@-webkit-keyframes createBox {
  from {
    height: 200px;
    width: 0px;
  }
  to {
    height: 200px;
    width: 200px;
  }
}

@keyframes createBox {
  from {
    height: 200px;
    width: 0px;
  }
  to {
    height: 200px;
    width: 200px;
  }
}

.drop-down {
  position: absolute;
  background-color: white;
  margin-top: 20px;
  width: 200px;
  height: 280px;
  border-top: 1px solid black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
  display: none;
}

.drop-down .drop-down-link {
  margin: 10px 0px;
  margin-left: 30px;
  text-decoration: none;
  padding: 0;
  color: black;
}

.navbar-button-projects:hover {
  height: 40px;
}

.navbar-button-projects:hover .drop-down {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* animation: createBox 0.3s; */
}

.mobile-menu {
  width: 50px;
  height: 30px;
}

@media (min-width: 470px) {
  .mobile-menu {
    display: none;
  }
}

.mobile-menu button {
  background: none;
  border: none;
}

.burger-menu-icon {
  width: 26px;
  height: 16px;
}

@media (min-width: 470px) {
  .burger-menu-icon {
    display: none;
  }
}

@-webkit-keyframes openBurgerMenu {
  from {
    left: 100vw;
  }
  to {
    left: 0;
  }
}

@keyframes openBurgerMenu {
  from {
    left: 100vw;
  }
  to {
    left: 0;
  }
}

@-webkit-keyframes closeBurgerMenu {
  from {
    left: 0;
  }
  to {
    left: 100vw;
  }
}

@keyframes closeBurgerMenu {
  from {
    left: 0;
  }
  to {
    left: 100vw;
  }
}

.burger-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #6600f7;
  padding-top: 40px;
}

@media (min-width: 470px) {
  .burger-menu {
    display: none;
  }
}

.burger-menu-top {
  width: 90%;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.burger-menu-top button {
  background: none;
  border: none;
  padding: 0;
}

.burger-menu-top button img {
  width: 20px;
}

@-webkit-keyframes closeBurgerProjectsDropdown {
  from {
    height: 200px;
    opacity: 1;
  }
  to {
    opacity: 0;
    height: 0px;
  }
}

@keyframes closeBurgerProjectsDropdown {
  from {
    height: 200px;
    opacity: 1;
  }
  to {
    opacity: 0;
    height: 0px;
  }
}

@-webkit-keyframes openBurgerProjectsDropdown {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    opacity: 1;
    height: 200px;
  }
}

@keyframes openBurgerProjectsDropdown {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    opacity: 1;
    height: 200px;
  }
}

.burger-menu-projects {
  width: 90%;
  margin: auto;
}

.burger-menu-projects div {
  height: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-bottom: 25px;
  border-bottom: 1px solid white;
}

.burger-menu-projects div a {
  margin-top: 15px;
  color: white !important;
}

.burger-menu-projects-button {
  margin-top: 50px;
  width: 100%;
  background: none;
  border: none;
  padding: 0;
  color: white !important;
  border-bottom: 1px solid white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
}

.burger-menu-about-button {
  width: 90%;
  margin: auto;
  font-size: 16px;
  margin-top: 20px;
}

.burger-menu-about-button a {
  color: white !important;
}

@-webkit-keyframes slideInFromLeft {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(30);
            transform: scale(30);
  }
}

@keyframes slideInFromLeft {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(30);
            transform: scale(30);
  }
}

@-webkit-keyframes tocolor {
  0% {
    background-color: black;
  }
  100% {
    background-color: #f5f5f1;
  }
}

@keyframes tocolor {
  0% {
    background-color: black;
  }
  100% {
    background-color: #f5f5f1;
  }
}

@-webkit-keyframes slideInFromLeft2 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  90% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes slideInFromLeft2 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  90% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0;
  }
}

@-webkit-keyframes fadeout {
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeout {
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.main-load {
  -webkit-animation: 3s ease-out 0s 1 fadeout, 3s tocolor;
          animation: 3s ease-out 0s 1 fadeout, 3s tocolor;
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.main-load .circle {
  -webkit-animation: 2.8s ease-out 0s 1 slideInFromLeft;
          animation: 2.8s ease-out 0s 1 slideInFromLeft;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #f5f5f1;
}

.main-load .fixed-circle {
  -webkit-animation: 2s ease-out 0s 1 slideInFromLeft2;
          animation: 2s ease-out 0s 1 slideInFromLeft2;
  background-color: #1d1d1d;
  position: absolute;
  border: 1px solid black;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.main-load .fixed-circle h4 {
  font-weight: 100;
  color: #f5f5f1;
}

.prj-header-img {
  width: 100%;
}

.prj-header-img img {
  width: 100%;
  height: auto;
}

@media (max-width: 470px) {
  .prj-header-img {
    display: none;
  }
}

.prj-header-img-mobile {
  display: none;
}

@media (max-width: 470px) {
  .prj-header-img-mobile {
    display: block;
    width: 100%;
  }
  .prj-header-img-mobile img {
    width: 100%;
    height: auto;
  }
}

.prj-info-head {
  width: 650px;
  margin: auto;
  margin-top: 70px;
}

@media (max-width: 470px) {
  .prj-info-head {
    width: 90%;
    margin-top: 30px;
  }
  .prj-info-head h1 {
    font-weight: 100;
    font-size: 1.9em;
  }
}

.prj-info-head p {
  font-family: roobert light;
  font-weight: 100;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.prj-info-head-2 {
  width: 650px;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.prj-info-head-2 h4 {
  margin-bottom: 0;
  padding-bottom: 0;
}

@media (max-width: 470px) {
  .prj-info-head-2 {
    width: 90%;
    margin-top: 30px;
  }
  .prj-info-head-2 h1 {
    font-weight: 100;
    font-size: 1.9em;
  }
}

.prj-info-container {
  width: 650px;
  margin: auto;
  margin-top: 70px;
}

@media (max-width: 470px) {
  .prj-info-container {
    width: 90%;
  }
}

.prj-info-container div {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 32px;
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 50vh;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.prj-info-container div h3 {
  padding: 15px;
  font-size: 14px;
  font-weight: 300;
}

.prj-info-container h4 {
  font-size: 24px;
}

.prj-info-container p {
  font-family: roobert light;
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.prj-info-container li {
  font-family: roobert light;
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.prj-info-container-mobile {
  display: none;
}

@media (max-width: 470px) {
  .prj-info-container-mobile {
    display: block;
    width: 90%;
    margin: auto;
    margin-top: 70px;
  }
}

.prj-info-container-mobile div {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 32px;
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 50vh;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.prj-info-container-mobile div h3 {
  padding: 15px;
  font-size: 14px;
  font-weight: 300;
}

.prj-info-container-mobile h4 {
  font-size: 24px;
}

.prj-info-container-mobile p {
  font-family: roobert light;
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.prj-info-container-mobile li {
  font-family: roobert light;
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.site-map {
  width: 990px;
  margin: auto;
  margin-top: 70px;
}

@media (max-width: 470px) {
  .site-map {
    width: 90%;
  }
}

.site-map img {
  width: 100%;
  height: auto;
}

.blog-map {
  width: 990px;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
}

@media (max-width: 470px) {
  .blog-map {
    width: 90%;
  }
}

.blog-map img {
  width: 100%;
  height: auto;
}

.final-map {
  width: 100%;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  height: 450px;
}

@media (max-width: 470px) {
  .final-map {
    display: none;
  }
}

.final-map img {
  width: 100%;
  height: auto;
}

.centered {
  position: absolute;
  top: 50%;
  left: 10%;
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
  width: 500px;
}

@media (max-width: 470px) {
  .centered {
    display: none;
  }
}

.centered h1 {
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 15px;
}

.centered span {
  font-size: 14px;
  color: #747474;
  font-weight: 400;
}

.centered div {
  margin-top: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.centered div a {
  font-size: 14px;
  font-weight: 500;
  color: black;
  margin-right: 5px;
}

.final-map-mobile {
  display: none;
}

@media (max-width: 470px) {
  .final-map-mobile {
    display: block;
    width: 100%;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 0;
    position: relative;
    height: 450px;
  }
  .final-map-mobile img {
    width: 100%;
    height: auto;
  }
}

.centered-mobile {
  display: none;
}

@media (max-width: 470px) {
  .centered-mobile {
    display: block;
    width: 90%;
    margin: auto;
  }
  .centered-mobile h1 {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  .centered-mobile span {
    font-size: 14px;
    color: #747474;
    font-weight: 400;
  }
  .centered-mobile div {
    margin-top: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .centered-mobile div a {
    font-size: 14px;
    font-weight: 500;
    color: black;
    margin-right: 5px;
  }
}

.centered-mobile-bs {
  display: none;
}

@media (max-width: 470px) {
  .centered-mobile-bs {
    position: absolute;
    display: block;
    width: 90%;
    margin: auto;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .centered-mobile-bs h1 {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  .centered-mobile-bs span {
    font-size: 14px;
    color: #747474;
    font-weight: 400;
  }
  .centered-mobile-bs div {
    margin-top: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .centered-mobile-bs div a {
    font-size: 14px;
    font-weight: 500;
    color: black;
    margin-right: 5px;
  }
}

.benchmarking-map {
  width: 1300px;
  margin: auto;
  margin-top: 70px;
}

@media (max-width: 470px) {
  .benchmarking-map {
    display: none;
    width: 90%;
  }
}

.benchmarking-map img {
  width: 100%;
  height: auto;
}

.user-persona {
  width: 1000px;
  margin: auto;
  margin-top: 70px;
}

@media (max-width: 470px) {
  .user-persona {
    display: none;
    width: 90%;
  }
}

.user-persona img {
  width: 100%;
  height: auto;
}

.form-container {
  width: 1000px;
  margin: auto;
  margin-top: 70px;
}

@media (max-width: 470px) {
  .form-container {
    width: 90%;
  }
}

.category-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 650px;
  margin: auto;
  margin-top: 70px;
}

@media (max-width: 470px) {
  .category-container {
    width: 90%;
  }
}

.category-container div {
  width: 50%;
}

.category-container div p {
  font-family: roobert light;
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.mobile-img {
  display: none;
}

@media (max-width: 470px) {
  .mobile-img {
    width: 100%;
    display: block;
    margin: auto;
    margin-top: 50px;
  }
  .mobile-img img {
    width: 100%;
  }
}

@media (max-width: 470px) {
  .form-container-parent {
    display: none;
  }
}

.blogCaroussel {
  overflow-x: hidden;
  overflow-y: hidden;
  background: #0f0f0f;
  color: white;
  margin-top: 40px;
}

@media (max-width: 470px) {
  .blogCaroussel {
    display: none;
  }
}

.blogCaroussel--panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 500%;
  height: 800px;
  background-color: white;
}

.blogCaroussel--panel-container {
  position: relative;
  height: 50vw;
  padding-top: 0;
  width: 650px;
  margin-left: 100px;
  margin-right: 80px;
}

.blogCaroussel--panel-container_image {
  width: 100%;
  top: 10vw;
  z-index: 10;
  margin-top: 150px;
}

.blogCaroussel--panel-container_description {
  bottom: 0;
  left: 0;
  width: 70%;
  background-color: red;
}

.carousel-mobile {
  display: none;
}

@media (max-width: 470px) {
  .carousel-mobile {
    display: block;
    margin-top: 50px;
  }
}

.carousel-mobile-content {
  width: 80%;
  background-color: white;
  padding: 30px;
}

.carousel-mobile-content img {
  width: 100%;
  margin: auto;
}

.awssld__bullets button {
  width: 12px !important;
  height: 12px !important;
  background-color: rgba(0, 0, 0, 0);
  background: rgba(255, 0, 0, 0) !important;
  border: 1px solid rgba(0, 0, 0, 0.6) !important;
}

.awssld__content {
  background-color: white !important;
}

.awssld__bullets .awssld__bullets--active {
  background-color: rgba(0, 0, 0, 0.6) !important;
  border: none !important;
  width: 10px !important;
  height: 10px !important;
}

.footer {
  width: 100%;
  background-color: white;
}

.footer-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: white;
  height: 450px;
  padding: 100px 150px 0;
}

@media (min-width: 1600px) {
  .footer-container {
    margin: 0 auto;
    max-width: 1240px;
  }
}

.footer-container div {
  height: 300px;
}

@media (max-width: 470px) {
  .footer-container {
    padding: 50px 20px;
    height: 350px;
  }
}

.footer-container-left {
  height: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.footer-container-left h1 {
  font-size: 48px;
  font-weight: 500;
}

@media (max-width: 470px) {
  .footer-container-left h1 {
    font-size: 36px;
  }
}

.footer-container-left a {
  padding: 15px 20px;
  width: 120px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  color: black;
  border-radius: 50vh;
  font-weight: 100;
  border: 1px solid black;
  background: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.footer-container-left a:hover {
  color: #6600f7;
  border: 1px solid #6600f7;
}

.footer-container-right {
  height: 300px;
}

@media (max-width: 470px) {
  .footer-container-right {
    display: none;
  }
}

.footer-container-right h3 {
  color: #626260;
  font-weight: 400;
}

.footer-container-right ul {
  padding-left: 0;
}

.footer-container-right ul li {
  list-style: none;
  margin-bottom: 20px;
}

.footer-container-right ul li a {
  color: black;
  text-decoration: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.footer-container-right ul li a:hover {
  color: #6600f7;
}

.about {
  background-color: none;
  margin: 0 10%;
  margin-top: 182px;
  height: 500px;
}

.about h1 {
  border-bottom: 1px solid black;
  font-size: 48px;
  font-weight: 500;
  padding-bottom: 20px;
}

@media (max-width: 470px) {
  .about {
    height: 850px;
  }
  .about h1 {
    font-size: 36px;
  }
}

.about-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media (max-width: 470px) {
  .about-content {
    display: block;
    width: 100%;
    padding: 0;
  }
}

.about-content-left {
  width: 50%;
}

.about-content-left p {
  margin-top: 0;
  font-size: 20px;
  font-weight: 300;
}

@media (max-width: 470px) {
  .about-content-left {
    display: block;
    width: 100%;
    margin: auto;
    padding-right: 0;
  }
  .about-content-left p {
    font-size: 18px;
    width: 100%;
    font-weight: 100;
  }
}

.about-content-right div {
  border-radius: 50%;
  overflow: hidden;
  height: 168px;
  width: 168px;
  margin-bottom: 20px;
}

.about-content-right div img {
  height: 100%;
  width: 100%;
}

@media (max-width: 470px) {
  .about-content-right {
    margin-top: 50px;
  }
}

.about-content-right form button {
  margin: auto;
  padding: 15px 20px;
  width: 168px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  color: black;
  border-radius: 50vh;
  font-weight: 100;
  border: 1px solid black;
  background: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 470px) {
  .about-content-right form button {
    margin: 0;
  }
}

.about-content-right form button:hover {
  color: #6600f7;
  border: 1px solid #6600f7;
}

@font-face {
  font-family: roobert;
  src: url(./Fonts/Roobert/Roobert-Regular.otf);
}

@font-face {
  font-family: roobert light;
  src: url(./Fonts/Roobert/Roobert-Light.otf);
}

body {
  background-color: #f5f5f1;
  font-family: roobert;
  margin: 0;
  /* padding: 0px 120px; */
}
