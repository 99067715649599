@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./styles/home";
@import "./styles/navbar";
@import "./styles/load";
@import "./styles/birdsong";
@import "./styles/footer";
@import "./styles/about";
@font-face {
  font-family: roobert;
  src: url(./Fonts/Roobert/Roobert-Regular.otf);
}

@font-face {
  font-family: roobert light;
  src: url(./Fonts/Roobert/Roobert-Light.otf);
}

$main-back: #f5f5f1;

body {
  background-color: $main-back;
  font-family: roobert;
  margin: 0;
  /* padding: 0px 120px; */
}
