@keyframes slideInFromLeft {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(30);
  }
}

@keyframes tocolor {
  0% {
    background-color: black;
  }
  100% {
    background-color: #f5f5f1;
  }
}

@keyframes slideInFromLeft2 {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  90% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes fadeout {
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

$main-back: #f5f5f1;

.main-load {
  animation: 3s ease-out 0s 1 fadeout, 3s tocolor;
  background-color: rgb(0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .circle {
    animation: 2.8s ease-out 0s 1 slideInFromLeft;
    margin: auto;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: $main-back;
  }

  .fixed-circle {
    animation: 2s ease-out 0s 1 slideInFromLeft2;
    background-color: rgb(29, 29, 29);
    position: absolute;
    border: 1px solid black;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
      font-weight: 100;
      color: $main-back;
    }
  }
}
