.home {
  background-color: none;
  margin: 0 10%;
  max-width: 1240px;

  @media (min-width: 1600px) {
    margin: 0 auto;
    max-width: 1240px;
  }
  @media (max-width: 470px) {
    font-size: 14px;
    margin: 0 6%;
  }
}

.top-container {
  margin-top: 180px;
  border: 1px solid none;
  width: 65%;
  @media (max-width: 470px) {
    width: 100%;
    margin-top: 120px;
  }

  h4 {
    font-size: 18px;
    font-weight: 400;

    @media (max-width: 470px) {
      font-size: 14px;
    }
  }

  h1 {
    font-size: 48px;
    font-weight: 500;
    @media (max-width: 470px) {
      font-size: 37px;
    }
  }

  a {
    padding: 15px 20px;
    width: 120px;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    color: black;
    border-radius: 50vh;
    font-weight: 100;
    border: 1px solid black;
    background: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
  }
  a:hover {
    color: #6600f7;
    border: 1px solid #6600f7;
  }
}

.projects-container {
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 470px) {
    display: block;
  }

  .column-2 {
    margin-top: 80px;
  }
}

.project-card {
  display: flex;
  flex-direction: column;
  width: 460px;
  margin-bottom: 100px;

  @media (max-width: 470px) {
    width: 100%;
  }

  div {
    height: 460px;
    width: 460px;

    @media (max-width: 470px) {
      width: 100%;
      height: 100vw;
    }

    img {
      width: 100%;
      height: auto;
      @media (max-width: 470px) {
        width: 100%;
      }
    }
  }

  span {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.6);
    @media (max-width: 470px) {
      font-size: 16px;
      margin-top: 5px;
    }
  }

  h2 {
    font-size: 36px;
    font-weight: 600;
    margin: 20px 0px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    margin: 0px 0px;
  }

  a {
    text-decoration: none;
    color: white;
  }
}

.project-card-hover {
  background-color: #6600f7;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 460px;
  width: 460px;
}

.card-icon {
  height: 70px !important;
  width: 70px !important;
}
