.project-page-container {
  @media (max-width: 470px) {
  }
}

.prj-header-img {
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 470px) {
    display: none;
  }
}

.prj-header-img-mobile {
  display: none;

  @media (max-width: 470px) {
    display: block;
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.prj-info-head {
  width: 650px;
  margin: auto;
  margin-top: 70px;

  @media (max-width: 470px) {
    width: 90%;
    margin-top: 30px;
    h1 {
      font-weight: 100;
      font-size: 1.9em;
    }
  }

  p {
    font-family: roobert light;
    font-weight: 100;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
  }
}

.prj-info-head-2 {
  width: 650px;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;

  h4 {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  @media (max-width: 470px) {
    width: 90%;
    margin-top: 30px;
    h1 {
      font-weight: 100;
      font-size: 1.9em;
    }
  }
}

.prj-info-container {
  width: 650px;
  margin: auto;
  margin-top: 70px;

  @media (max-width: 470px) {
    width: 90%;
  }

  div {
    width: fit-content;
    height: 32px;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 50vh;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
      padding: 15px;
      font-size: 14px;
      font-weight: 300;
    }
  }

  h4 {
    font-size: 24px;
  }

  p {
    font-family: roobert light;
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
  }
  li {
    font-family: roobert light;
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
  }
}

.prj-info-container-mobile {
  display: none;

  @media (max-width: 470px) {
    display: block;
    width: 90%;
    margin: auto;
    margin-top: 70px;
  }

  div {
    width: fit-content;
    height: 32px;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 50vh;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
      padding: 15px;
      font-size: 14px;
      font-weight: 300;
    }
  }

  h4 {
    font-size: 24px;
  }

  p {
    font-family: roobert light;
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
  }
  li {
    font-family: roobert light;
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
  }
}

.site-map {
  width: 990px;
  margin: auto;
  margin-top: 70px;

  @media (max-width: 470px) {
    width: 90%;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.blog-map {
  width: 990px;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;

  @media (max-width: 470px) {
    width: 90%;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.final-map {
  width: 100%;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  height: 450px;

  @media (max-width: 470px) {
    display: none;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.centered {
  @media (max-width: 470px) {
    display: none;
  }

  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(0%, -50%);
  width: 500px;

  h1 {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  span {
    font-size: 14px;
    color: #747474;
    font-weight: 400;
  }

  div {
    margin-top: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    a {
      font-size: 14px;
      font-weight: 500;
      color: black;
      margin-right: 5px;
    }
  }
}

.final-map-mobile {
  display: none;

  @media (max-width: 470px) {
    display: block;
    width: 100%;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 0;
    position: relative;
    height: 450px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.centered-mobile {
  display: none;

  @media (max-width: 470px) {
    display: block;
    width: 90%;
    margin: auto;

    h1 {
      font-size: 26px;
      font-weight: 500;
      margin-bottom: 15px;
    }

    span {
      font-size: 14px;
      color: #747474;
      font-weight: 400;
    }

    div {
      margin-top: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      a {
        font-size: 14px;
        font-weight: 500;
        color: black;
        margin-right: 5px;
      }
    }
  }
}

.centered-mobile-bs {
  display: none;

  @media (max-width: 470px) {
    position: absolute;
    display: block;
    width: 90%;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h1 {
      font-size: 26px;
      font-weight: 500;
      margin-bottom: 15px;
    }

    span {
      font-size: 14px;
      color: #747474;
      font-weight: 400;
    }

    div {
      margin-top: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      a {
        font-size: 14px;
        font-weight: 500;
        color: black;
        margin-right: 5px;
      }
    }
  }
}

.benchmarking-map {
  width: 1300px;
  margin: auto;
  margin-top: 70px;

  @media (max-width: 470px) {
    display: none;
    width: 90%;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.user-persona {
  width: 1000px;
  margin: auto;
  margin-top: 70px;

  @media (max-width: 470px) {
    display: none;
    width: 90%;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.form-container {
  width: 1000px;
  margin: auto;
  margin-top: 70px;

  @media (max-width: 470px) {
    width: 90%;
  }
}

.category-container {
  display: flex;
  justify-content: space-between;
  width: 650px;
  margin: auto;
  margin-top: 70px;

  @media (max-width: 470px) {
    width: 90%;
  }

  div {
    width: 50%;

    p {
      font-family: roobert light;
      font-size: 14px;
      font-weight: 100;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}

.mobile-img {
  display: none;
  @media (max-width: 470px) {
    width: 100%;
    display: block;
    margin: auto;
    margin-top: 50px;

    img {
      width: 100%;
    }
  }
}

.form-container-parent {
  @media (max-width: 470px) {
    display: none;
  }
}

//Carrousel

.blogCaroussel {
  overflow-x: hidden;
  overflow-y: hidden;
  background: #0f0f0f;
  color: white;
  margin-top: 40px;

  @media (max-width: 470px) {
    display: none;
  }
}
.blogCaroussel--panel {
  display: flex;
  width: 500%;
  height: 800px;
  background-color: white;
}
.blogCaroussel--panel-container {
  position: relative;
  height: 50vw;
  padding-top: 0;
  width: 650px;
  margin-left: 100px;
  margin-right: 80px;
}
.blogCaroussel--panel-container_image {
  width: 100%;
  top: 10vw;
  z-index: 10;
  margin-top: 150px;
}
.blogCaroussel--panel-container_description {
  bottom: 0;
  left: 0;
  width: 70%;
  background-color: red;
}

.carousel-mobile {
  display: none;
  @media (max-width: 470px) {
    display: block;
    margin-top: 50px;
  }
}
.carousel-mobile-content {
  width: 80%;
  background-color: white;
  padding: 30px;

  img {
    width: 100%;
    margin: auto;
  }
}

.awssld__bullets button {
  width: 12px !important;
  height: 12px !important;
  background-color: rgba(0, 0, 0, 0);
  background: rgba(255, 0, 0, 0) !important;

  border: 1px solid rgba(0, 0, 0, 0.6) !important;
}

.awssld__content {
  background-color: white !important;

}

.awssld__bullets {
  .awssld__bullets--active {
    background-color: rgba(0, 0, 0, 0.6) !important;
    border: none !important;
    width: 10px !important;
    height: 10px !important;
  }
}
