.footer {
  width: 100%;
  background-color: white;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  background-color: white;
  height: 450px;
  padding: 100px 150px 0;

  @media (min-width: 1600px) {
    margin: 0 auto;
    max-width: 1240px;
  }

  div {
    height: 300px;
  }

  @media (max-width: 470px) {
    padding: 50px 20px;
    height: 350px;
  }
}

.footer-container-left {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    font-size: 48px;
    font-weight: 500;
    @media (max-width: 470px) {
      font-size: 36px;
    }
  }

  a {
    padding: 15px 20px;
    width: 120px;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    color: black;
    border-radius: 50vh;
    font-weight: 100;
    border: 1px solid black;
    background: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
  }
  a:hover {
    color: #6600f7;
    border: 1px solid #6600f7;
  }
}

.footer-container-right {
  height: 300px;
  @media (max-width: 470px) {
    display: none;
  }

  h3 {
    color: #626260;
    font-weight: 400;
  }

  ul {
    padding-left: 0;
    li {
      list-style: none;
      margin-bottom: 20px;

      a {
        color: black;
        text-decoration: none;
        transition: all 0.3s;
      }
      a:hover {
        color: #6600f7;
      }
    }
  }
}
