.about {
  background-color: none;
  margin: 0 10%;
  margin-top: 182px;
  height: 500px;
  h1 {
    border-bottom: 1px solid black;
    font-size: 48px;
    font-weight: 500;
    padding-bottom: 20px;
  }
  @media (max-width: 470px) {
    height: 850px;

    h1 {
      font-size: 36px;
    }
  }
}

.about-content {
  display: flex;
  justify-content: space-between;
  @media (max-width: 470px) {
    display: block;
    width: 100%;
    padding: 0;
  }
}

.about-content-left {
  width: 50%;
  p {
    margin-top: 0;
    font-size: 20px;
    font-weight: 300;
  }
  @media (max-width: 470px) {
    display: block;
    width: 100%;
    margin: auto;
    padding-right: 0;

    p {
      font-size: 18px;
      width: 100%;
      font-weight: 100;
    }
  }
}

.about-content-right {
  div {
    border-radius: 50%;
    overflow: hidden;
    height: 168px;
    width: 168px;
    margin-bottom: 20px;

    img {
      height: 100%;
      width: 100%;
    }
  }
  @media (max-width: 470px) {
    margin-top: 50px;
  }
  form {
    button {
      margin: auto;
      padding: 15px 20px;
      width: 168px;
      font-size: 14px;
      font-weight: 400;
      text-decoration: none;
      color: black;
      border-radius: 50vh;
      font-weight: 100;
      border: 1px solid black;
      background: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      transition: all 0.3s;
      @media (max-width: 470px) {
        margin: 0;
      }
    }
    button:hover {
      color: #6600f7;
      border: 1px solid #6600f7;
    }
  }
}
